// REACT
import { useContext } from 'react'
import { Link } from 'react-router-dom'

// LOGO
import logo from '../../assets/images/logo.png'

// CONTEXTS
import { AppContext } from '../../contexts/App'
import { MDBootstrapContext } from '../../contexts/MDBootstrap'

export default function Brand(){

  const {
    PATHS,
    TEXTS
  } = useContext(AppContext)

  const {
    MDBNavbarBrand
  } = useContext(MDBootstrapContext)

  return(
    <MDBNavbarBrand tag={Link} to={PATHS.HOME}>
      <img
        src={logo}
        height='40'
        alt={TEXTS.FULL_NAME}
        loading='lazy'
      />
    </MDBNavbarBrand>
  )
}
