import { useContext } from "react"
import { MDBootstrapContext } from "../contexts/MDBootstrap"
import { Link } from "react-router-dom"
import { AppContext } from "../contexts/App"
import { toTop } from "../utils/topTopScroll"

export default function ActivitiesCards() {

  const {
    MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
    MDBTypography
  } = useContext(MDBootstrapContext)

  const {
    PATHS
  } = useContext(AppContext)

  return(
    <MDBContainer className="my-5">
      <MDBTypography variant='h2' className="text-center my-4">Atividades</MDBTypography>
      <p>Nossas atividades são gratuitas e abertas a todos que buscam conhecimento e crescimento espiritual. Venha conhecer nossa casa e participar de nossas atividades! <b>Aguardamos a sua visita!</b> </p>
      <MDBRow>
        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Doutrinária</MDBCardTitle>
              <MDBCardText className="text-start card-body-personalized">
                Todas terças-feiras a partir das 20hs. Com palestras e passes
              </MDBCardText>
              <Link to={PATHS.ACTIVITIES + "/" + PATHS.MEDIUMISTIC} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Palestras</MDBCardTitle>
              <MDBCardText className="text-start card-body-personalized">
                São reuniões com entrada franca a todos que tiverem interesse e necessitarem.
              </MDBCardText>
              <Link to={PATHS.ACTIVITIES + "/" + PATHS.SPEECHES} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Passes</MDBCardTitle>
              <MDBCardText className="text-start card-body-personalized">
              Prática que pode trazer muitos benefícios para a saúde física, mental e espiritual.
              </MDBCardText>
              <Link to={PATHS.ACTIVITIES + "/" + PATHS.PASS} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Atendimento fraterno</MDBCardTitle>
              <MDBCardText className="text-start card-body-personalized">
                Consiste em receber todos aqueles que estão entrando pela primeira vez em uma instituição Espírita.
              </MDBCardText>
              <Link to={PATHS.ACTIVITIES + "/" + PATHS.FRATERNAL_ASSISTANCE} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

      </MDBRow>
    </MDBContainer>
  )
}
