
export const TEXTS = Object.freeze({
  FULL_NAME: "Grupo Espírita Beneficente Emmanuel",
  SHORT_NAME: "G.E.B.E",
  WELCOME: "Seja bem vindo(a) ao Grupo Espítita Beneficente Emmanuel",
  SHORT_WELCOME: "Seja bem vindo ao G.E.B.E",

  IRRADIATION_PRAYER: {
    TITLE: "Prece de Irradiação",
    INTRODUCTION: "Transmissão de fluidos espirituais a distância ou passe a distância em favor daqueles aos quais dirigimos nossos pensamentos."
  }
})
