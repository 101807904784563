
import ActivitiesCards from "../components/ActivitiesCards"
// import Banner from "../components/Banner"
import Mantra from "../components/Mantra"
import OpenHours from "../components/OpenHours"
import StudiesCards from "../components/StudiesCards"

export default function Home() {
  return(
    <>
      {/* <Banner /> */}
      <OpenHours />
      <ActivitiesCards />
      <StudiesCards />
      <Mantra />
    </>
  )
}
