// REACT
import { useContext } from "react"

// CONTEXT
import { MDBootstrapContext } from "../contexts/MDBootstrap"
import { AppContext } from "../contexts/App"

// HOOK
import useCheckMobileScreen from "../hooks/useCheckMobileScreen"



export default function TopHeader() {

  const toggleWidth = useCheckMobileScreen()

  const {
    TEXTS
  } = useContext(AppContext)

  const {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon
  } = useContext(MDBootstrapContext)

  return(
    <MDBContainer className="bg-screeching-white py-2" fluid style={{maxHeight: '42px'}}>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="d-flex justify-content-start">
            <span className="text-nowrap">
              {toggleWidth === false
                ? TEXTS.WELCOME
                : TEXTS.SHORT_WELCOME
              }
            </span>
          </MDBCol>
          <MDBCol className="d-flex justify-content-end">
            {/* MAX 3 SOCIAL MEDIA LINKS */}
            {/* <MDBRow>
              <MDBCol>
                <a
                  href="//facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap"
                >
                  <MDBIcon fab icon="facebook" /> &nbsp;
                  {toggleWidth === false ? 'Facebook' : ''}
                </a>
              </MDBCol>
              <MDBCol>
                <a
                  href="//youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap"
                >
                  <MDBIcon fab icon="youtube" /> &nbsp;
                  {toggleWidth === false ? 'Youtube' : ''}
                </a>
              </MDBCol>
              <MDBCol>
                <a
                  href="//instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap"
                >
                  <MDBIcon fab icon="instagram" /> &nbsp;
                  {toggleWidth === false ? 'Instagram' : ''}
                </a>
              </MDBCol>
            </MDBRow> */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  )
}
