import { useContext } from "react"
import { MDBootstrapContext } from "../contexts/MDBootstrap"

// import team from '../assets/images/temp-team.jpg'

export default function Mantra() {

  const {
    MDBContainer
  } = useContext(MDBootstrapContext)

  return(
    <MDBContainer className="my-5">
      <div className="fancy-frame">
        <p className="text-center text-uppercase fs-2">Este é o grupo espírita Emmanuel, que eleva seu nome promovendo o bem, onde a caridade está sempre presente independente de se olhar a quem.</p>
        <p className="text-center text-uppercase fs-2">Que leva conforto aos seres humanos, lugar onde os irmãos serão sempre benquistos sob a orientação dos divinos planos, conforme os ensinamentos de Cristo.</p>
      </div>
    </MDBContainer>
  )
}
