import { useContext } from "react"
import { MDBootstrapContext } from "../contexts/MDBootstrap"

export default function OpenHours() {

  const {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBTypography
  } = useContext(MDBootstrapContext)

  return(
    <MDBContainer className="my-5">
      <MDBTypography variant='h2' className="text-center">Horários</MDBTypography>
      <MDBRow className="my-4">
        <MDBCol md="6" className="text-center d-flex align-items-center justify-content-center">
          <p className="fs-4">A casa oferece as atividades de Doutrinária, palestra, <br /> passes, ESDE e Estudos mediunicos <br /> nos seguintes dias e horários:</p>
        </MDBCol>
        <MDBCol md="6" className="d-flex align-items-center justify-content-center">
          <ul className="opening-time">
            <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Segunda:</strong> Fechado</li>
            <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Terça:</strong> 20hs - 21hs (Doutrinária, palestra e passes)</li>
            <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Quarta:</strong> 19:30hs - 21hs (ESDE)</li>
            <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Quinta:</strong> 19:30hs - 21hs (Estudo Mediunico)</li>
            <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Sexta:</strong> Fechado</li>
            <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Sábado:</strong> Fechado</li>
            <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Domingo:</strong> Fechado</li>
          </ul>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
