// REACT
import { useContext } from 'react'

// CONTEXT
import { MDBootstrapContext } from '../../contexts/MDBootstrap'

export default function MenuToggler({
  showBasic,
  setShowBasic
}) {

  const {
    MDBNavbarToggler,
    MDBIcon
  } = useContext(MDBootstrapContext)

  return(
    <MDBNavbarToggler
      aria-controls='navbarSupportedContent'
      aria-expanded='false'
      aria-label='Toggle navigation'
      onClick={() => setShowBasic(!showBasic)}
    >
      <MDBIcon fas icon="bars" />
    </MDBNavbarToggler>
  )
}
