import { useContext } from "react"
import { MDBootstrapContext } from "../contexts/MDBootstrap"
import { Link } from "react-router-dom"
import { AppContext } from "../contexts/App"
import { toTop } from "../utils/topTopScroll"

export default function StudiesCards() {

  const {
    MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
    MDBTypography
  } = useContext(MDBootstrapContext)

  const {
    PATHS
  } = useContext(AppContext)

  return(
    <MDBContainer className="my-5">
      <MDBTypography variant='h2' className="text-center my-4">Estudos da doutrina</MDBTypography>
      <p>O estudo da doutrina espírita permite ao indivíduo compreender melhor a sua própria existência, o seu lugar no universo e o seu propósito de vida. podendo ajudar a pessoa a superar dificuldades e desafios, oferecendo-lhe uma perspectiva mais ampla sobre a vida. E inspirar a pratica do bem e a caridade, contribuindo para a construção de um mundo mais justo e fraterno.</p>
      <MDBRow>
        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">História do Espíritismo</MDBCardTitle>
              <MDBCardText className="text-start">
                Doutrina espiritualista e reencarnacionista estabelecida na França em meados do século XIX.
              </MDBCardText>
              <Link to={PATHS.STUDIES + "/" + PATHS.HISTORY} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Obras de Kardec</MDBCardTitle>
              <MDBCardText className="text-start">
                Conhecido como o codificador da doutrina através do pentateuco no século XIX.
              </MDBCardText>
              <Link to={PATHS.STUDIES + "/" + PATHS.WORKS_OF_KARDEC} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">ESDE</MDBCardTitle>
              <MDBCardText className="text-start">
                Estudo Sistematizado da Doutrina Espírita, com base nas obras de Kardec e Jesus Cristo.
              </MDBCardText>
              <Link to={PATHS.STUDIES + "/" + PATHS.ESDE} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard alignment='end' className="my-2">
            <MDBCardBody>
              <MDBCardTitle className="text-center">Estudos mediunicos</MDBCardTitle>
              <MDBCardText className="text-start">
                Atividade destinada ao estudo, educação e desenvolvimento de médiuns.
              </MDBCardText>
              <Link to={PATHS.STUDIES + "/" + PATHS.MEDIUNIC_STUDIES} className="text-end" onClick={toTop}>
                Saiba mais...
              </Link>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

      </MDBRow>
    </MDBContainer>
  )
}
