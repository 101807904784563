import { useContext } from "react"
import { MDBootstrapContext } from "../../contexts/MDBootstrap"

export default function Copyright() {

  const {
    MDBCol
  } = useContext(MDBootstrapContext)

  const currentYear = new Date().getFullYear()

  return(
    <MDBCol className="col-md-4 box d-flex justify-content-center justify-content-md-start align-items-center">
      <span className="copyright quick-links">
        &copy; G.E.B.E {currentYear}
      </span>
    </MDBCol>
  )
}
