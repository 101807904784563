import React, { useContext } from 'react'
import { MDBootstrapContext } from '../contexts/MDBootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../contexts/App'
import Copyright from './common/Copyright'

import { toTop } from '../utils/topTopScroll'

export default function Footer() {

  const {
    PATHS
  } = useContext(AppContext)

  const {
    MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon
  } = useContext(MDBootstrapContext)

  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <MDBContainer className='d-flex justify-content-center p-4 border-bottom fs-4'>
        VENHA NOS VISITAR!
      </MDBContainer>

      <MDBContainer className='text-center text-md-start mt-5'>
        {/* first line */}
        <MDBRow className='mt-3'>
          <MDBCol md="6" lg="4" xl="3" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>
              Quem Somos
            </h6>
            <p>
              Instituição filantrópica sem fins lucrativos, tendo como orientador
              as obras básicas da codificação Kardequiana. Conheça mais do nosso trabalho
              clicando <Link to={PATHS.ABOUT_US + "/" + PATHS.G_E_B_E} onClick={toTop}>aqui</Link>.
            </p>
          </MDBCol>

          <MDBCol md="6" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>Atividades</h6>
            <Link to={PATHS.ACTIVITIES + "/" + PATHS.MEDIUMISTIC} onClick={toTop}>
              <p>
                Doutrinária
              </p>
            </Link>
            <Link to={PATHS.ACTIVITIES + "/" + PATHS.SPEECHES} onClick={toTop}>
              <p>
                Palestras
              </p>
            </Link>
            <Link to={PATHS.ACTIVITIES + "/" + PATHS.PASS} onClick={toTop}>
              <p>
                Passes
              </p>
            </Link>
            <Link to={PATHS.ACTIVITIES + "/" + PATHS.FRATERNAL_ASSISTANCE} onClick={toTop}>
              <p>
                Atendimento fraterno
              </p>
            </Link>
          </MDBCol>

          <MDBCol md="6" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>Links úteis</h6>
            <p>
              <a
                href="//www.febnet.org.br/portal/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nowrap"
              >
                FEB
              </a>
            </p>
            <p>
              <a
                href="//www.fergs.org.br/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nowrap"
              >
                FERGS
              </a>
            </p>
          </MDBCol>

          <MDBCol md="6" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>Contact</h6>
            <p>
              <MDBIcon icon="home" className="me-2" />
              R. Brg. Ivo Borges, 81 - São Luís, Canoas - RS, 92420-050
            </p>
            <p>
              <a
                href="https://wa.me/5551991129309/?text=Ol%C3%A1%2C%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20a%20casa."
                className='cl-sonic-silver'
              >
                <MDBIcon fab icon="whatsapp" className="me-2" />
                (51) 99112-9309
              </a>
            </p>
            <p>
              <a href="mailto:casa.espirita.emmanuel.canoas@gmail.com?subject=Contato pelo site.">
                <MDBIcon icon="envelope" className="me-2" />
                Via e-mail
              </a>
            </p>
          </MDBCol>
        </MDBRow>

        {/* second line */}
        <MDBRow className='mt-3'>
          <MDBCol md="6" lg="4" xl="3" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>horários</h6>
            <ul className="opening-time">
              <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Segunda:</strong> Fechado</li>
              <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Terça:</strong> 20hs - 21hs (Doutrinária, palestra e passes)</li>
              <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Quarta:</strong> 19:30hs - 21hs (ESDE)</li>
              <li><span><MDBIcon fas icon="check"/>&nbsp;</span><strong>Quinta:</strong> 19:30hs - 21hs (Estudo Mediunico)</li>
              <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Sexta:</strong> Fechado</li>
              <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Sábado:</strong> Fechado</li>
              <li><span><MDBIcon fas icon="times"/>&nbsp;</span><strong>Domingo:</strong> Fechado</li>
            </ul>
          </MDBCol>

          <MDBCol md="6" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>ESTUDOS</h6>
            <Link to={PATHS.STUDIES + "/" + PATHS.HISTORY} onClick={toTop}>
              <p>
                História do Espiritismo
              </p>
            </Link>
            <Link to={PATHS.STUDIES + "/" + PATHS.WORKS_OF_KARDEC} onClick={toTop}>
              <p>
                Obras de Kardec
              </p>
            </Link>
            <Link to={PATHS.STUDIES + "/" + PATHS.ESDE} onClick={toTop}>
              <p>
                ESDE
              </p>
            </Link>
            <Link to={PATHS.STUDIES + "/" + PATHS.MEDIUNIC_STUDIES} onClick={toTop}>
              <p>
                Estudos mediunicos
              </p>
            </Link>
          </MDBCol>

          <MDBCol md="6" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>Livros</h6>
            <Link to={PATHS.BOOKS + "/" + PATHS.TO_BORROW} onClick={toTop}>
              <p>
                Para empréstimo
              </p>
            </Link>
            <Link to={PATHS.BOOKS + "/" + PATHS.TO_SALES} onClick={toTop}>
              <p>
                Para venda
              </p>
            </Link>
          </MDBCol>

          <MDBCol md="6" lg="4" xl="3" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4 fancy-border'>AJUDA</h6>
            <Link to={PATHS.FAQ} onClick={toTop}>
              <p>
                FAQ
              </p>
            </Link>
          </MDBCol>
        </MDBRow>
      </MDBContainer>


      {/* DIVIDER */}
      <MDBContainer className="text-center"><MDBIcon fas icon="ellipsis-h" /></MDBContainer>

      {/* BELOW FOOTER */}
      <MDBContainer className="container py-4">
        <MDBRow>
          <Copyright />

          {/* SOCIAL MEDIA */}
          <MDBCol className="col-md-4 box">
            {/* MAX 3 SOCIAL MEDIA LINKS */}
            {/* <MDBRow>
              <MDBCol className="col-lg-4 d-flex d-lg-inline-flex justify-content-center">
                <a
                  href="//facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap d-flex align-items-center"
                >
                  <MDBIcon fab icon="facebook" size='2x' /> &nbsp;
                  Facebook
                </a>
              </MDBCol>
              <MDBCol className="col-lg-4 d-flex d-lg-inline-flex justify-content-center">
                <a
                  href="//youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap d-flex align-items-center"
                >
                  <MDBIcon fab icon="youtube" size='2x' /> &nbsp;
                  Youtube
                </a>
              </MDBCol>
              <MDBCol className="col-lg-4 d-flex d-lg-inline-flex justify-content-center">
                <a
                  href="//instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-secondary text-nowrap d-flex align-items-center"
                >
                  <MDBIcon fab icon="instagram" size='2x' /> &nbsp;
                  Instagram
                </a>
              </MDBCol>
            </MDBRow> */}
          </MDBCol>

          {/* SENTENCE */}
          <MDBCol className="col-md-4 my-2 my-sm-0 d-flex justify-content-center justify-content-lg-end align-items-center">
            <q>Fora da caridade não há salvação.</q>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  )
}
