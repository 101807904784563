
import { useContext } from 'react'
import { AppContext } from '../../contexts/App'

import logo from '../../assets/images/logo-bigger.png'

export default function Loader() {

  const {
    TEXTS,
    toggleLoader
  } = useContext(AppContext)

  return(
    <>
      {toggleLoader && (
        <div className='overlay'>
        <div className='row d-flex justify-content-center'>
          <img
            src={logo}
            alt={TEXTS.FULL_NAME}
            loading='lazy'
            className='img-fluid'
          />
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
        </div>
      )}
    </>
  )
}
