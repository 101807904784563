// REACT ROUTER DOM
import {
  createBrowserRouter
} from "react-router-dom"

// ROUTES PATHS
import { PATHS } from "./paths"

// LAYOUTS
import Grid from "../layouts/Grid"
import NoMatch from "../layouts/NoMatch"

// NON LAZY PAGES
import Home from "../pages/Home"

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Grid />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: PATHS.ABOUT_US,
        children: [
          {
            path: PATHS.G_E_B_E,
            async lazy() {
              let AboutUs = await import("../pages/AboutUs/AboutUs")
              return {
                Component: AboutUs.default
              }
            }
          },
          {
            path: PATHS.WHO_IS_EMMANUEL,
            async lazy() {
              let AboutUs = await import("../pages/AboutUs/WhoIsEmmanuel")
              return {
                Component: AboutUs.default
              };
            }
          },
        ]
      },
      {
        path: PATHS.ACTIVITIES,
        children: [
          {
            path: PATHS.MEDIUMISTIC,
            async lazy() {
              let Mediumistic = await import("../pages/Activities/Doctrinal")
              return {
                Component: Mediumistic.default
              }
            },
          },
          {
            path: PATHS.SPEECHES,
            async lazy() {
              let Speeches = await import("../pages/Activities/Speeches")
              return {
                Component: Speeches.default
              }
            },
          },
          {
            path: PATHS.PASS,
            async lazy() {
              let Pass = await import("../pages/Activities/Pass")
              return {
                Component: Pass.default
              }
            },
          },
          {
            path: PATHS.FRATERNAL_ASSISTANCE,
            async lazy() {
              let FraternalAssistance = await import("../pages/Activities/FraternalAssistance")
              return {
                Component: FraternalAssistance.default
              }
            },
          }
        ]
      },
      {
        path: PATHS.STUDIES,
        children: [
          {
            path: PATHS.HISTORY,
            async lazy() {
              let History = await import("../pages/Studies/HistoryOfSpiritism")
              return {
                Component: History.default
              }
            },
          },
          {
            path: PATHS.WORKS_OF_KARDEC,
            async lazy() {
              let WorksOfKardec = await import("../pages/Studies/WorksOfKardec")
              return {
                Component: WorksOfKardec.default
              }
            },
          },
          {
            path: PATHS.ESDE,
            async lazy() {
              let ESDE = await import("../pages/Studies/ESDE")
              return {
                Component: ESDE.default
              }
            },
          },
          {
            path: PATHS.MEDIUNIC_STUDIES,
            async lazy() {
              let MediunicStudies = await import("../pages/Studies/MediunicStudies")
              return {
                Component: MediunicStudies.default
              }
            },
          },
        ]
      },
      {
        path: PATHS.BOOKS,
        children: [
          {
            path: PATHS.TO_BORROW,
            async lazy() {
              let BooksToBorrow = await import("../pages/Books/BooksToBorrow")
              return {
                Component: BooksToBorrow.default
              }
            },
          },
          {
            path: PATHS.TO_SALES,
            async lazy() {
              let BooksForSale = await import("../pages/Books/BooksForSale")
              return {
                Component: BooksForSale.default
              }
            },
          }
        ]
      },
      {
        path: PATHS.FAQ,
        async lazy() {
          let FAQ = await import("../pages/Help/FAQ")
          return {
            Component: FAQ.default
          }
        }
      },
      {
        path: PATHS.TALK_TO_US,
        children: [
          {
            path: PATHS.CONTACT_US,
            async lazy() {
              let ContactUs = await import("../pages/Contacts/ContactUs")
              return {
                Component: ContactUs.default
              }
            }
          }
        ]
      },
      {
        path: "*",
        element: <NoMatch />,
      }
    ],
  },
])

export default routes

