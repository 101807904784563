export const PATHS = Object.freeze({
  HOME: '/',

  ABOUT_US: "sobre-a-casa",
    G_E_B_E: "grupo-espirita-beneficente-emmanuel",
    WHO_IS_EMMANUEL: "quem-e-emmanuel",

  ACTIVITIES: "atividades-da-casa",
    MEDIUMISTIC: "doutrinaria",
    SPEECHES: "palestras",
    PASS: "passes",
    FRATERNAL_ASSISTANCE: "atendimento-fraterno",

  STUDIES: "estudos",
    HISTORY: "historia-do-espiritismo",
    WORKS_OF_KARDEC: "obras-de-kardec",
    ESDE: "estudo-sistematizado-da-doutrina-espirita",
    MEDIUNIC_STUDIES: "estudos-mediunicos",

  BOOKS: "livros",
    TO_BORROW: "para-emprestimos",
    TO_SALES: "para-venda",

  FAQ: "perguntas-frequentes",

  TALK_TO_US: "fale-conosco",
    CONTACT_US: "contate-nos",
    IRRADIATION_PRAYER: "prece-de-irradiacao",
})
