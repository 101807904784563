// REACT ROUTER DOM
import { Outlet } from "react-router-dom"

// COMPONENTS
import TopHeader from "../components/TopHeader"
import Header from "../components/Header"
import Footer from "../components/Footer"



export default function Grid() {
  return(
    <>
      <TopHeader />
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
