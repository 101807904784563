// REACT
import { useContext } from 'react'
import { Link } from 'react-router-dom'

// CONTEXT
import { AppContext } from '../../contexts/App'
import { MDBootstrapContext } from '../../contexts/MDBootstrap'

// UTILS
import { toTop } from '../../utils/topTopScroll'

export default function Menu({
  showBasic
}) {

  const {
    PATHS
  } = useContext(AppContext)

  const {
    MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBDropdown, MDBDropdownToggle,
    MDBDropdownMenu, MDBDropdownItem, MDBCollapse
  } = useContext(MDBootstrapContext)

  return(
    <MDBCollapse navbar show={showBasic}>
      <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
        {/* HOME */}
        <MDBNavbarItem className="me-lg-3">
          <MDBNavbarLink tag={Link} to={PATHS.HOME} onClick={toTop}>
            Home
          </MDBNavbarLink>
        </MDBNavbarItem>

        {/* SOBRE A CASA */}
        <MDBNavbarItem className="me-lg-3">
          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Sobre a casa
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ABOUT_US + "/" + PATHS.G_E_B_E}
                  onClick={toTop}
                >
                  G.E.B.E
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ABOUT_US + "/" + PATHS.WHO_IS_EMMANUEL}
                  onClick={toTop}
                >
                  Quem é Emmanuel
                </MDBNavbarLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

        {/* ATIVIDADES */}
        <MDBNavbarItem className="me-lg-3">
          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Atividades
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ACTIVITIES + "/" + PATHS.MEDIUMISTIC}
                  onClick={toTop}
                >
                  Doutrinária
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ACTIVITIES + "/" + PATHS.SPEECHES}
                  onClick={toTop}
                >
                  Palestras
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ACTIVITIES + "/" + PATHS.PASS}
                  onClick={toTop}
                >
                  Passes
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.ACTIVITIES + "/" + PATHS.FRATERNAL_ASSISTANCE}
                  onClick={toTop}
                >
                  Atendimento fraterno
                </MDBNavbarLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

        {/* ESTUDOS */}
        <MDBNavbarItem className="me-lg-3">
          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Estudos
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.STUDIES + "/" + PATHS.HISTORY}
                  onClick={toTop}
                >
                  História do Espiritismo
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.STUDIES + "/" + PATHS.WORKS_OF_KARDEC}
                  onClick={toTop}
                >
                  Obras de Kardec
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.STUDIES + "/" + PATHS.ESDE}
                  onClick={toTop}
                >
                  ESDE
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.STUDIES + "/" + PATHS.MEDIUNIC_STUDIES}
                  onClick={toTop}
                >
                  Estudo Mediunico
                </MDBNavbarLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

        {/* LIVROS */}
        <MDBNavbarItem className="me-lg-3">
          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Livros
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.BOOKS + "/" + PATHS.TO_BORROW}
                  onClick={toTop}
                >
                  Para empréstimos
                </MDBNavbarLink>
              </MDBDropdownItem>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.BOOKS + "/" + PATHS.TO_SALES}
                  onClick={toTop}
                >
                  Para venda
                </MDBNavbarLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

        {/* AJUDA */}
        <MDBNavbarItem className="me-lg-3">
          <MDBNavbarLink tag={Link} to={PATHS.FAQ} onClick={toTop}>
            FAQ
          </MDBNavbarLink>
        </MDBNavbarItem>

        {/* CONTATE-NOS */}
        <MDBNavbarItem className="me-lg-3">
          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
              Contate-nos
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link>
                <MDBNavbarLink
                  tag={Link}
                  to={PATHS.TALK_TO_US + "/" + PATHS.CONTACT_US}
                  onClick={toTop}
                >
                  Fale conosco
                </MDBNavbarLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

      </MDBNavbarNav>
    </MDBCollapse>
  )
}
