import { MDBContainer } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"

export default function NoMatch() {
  return(
    <MDBContainer className="my-5">
      <h2>O que você tentou acessar não existe.</h2>
      <p>
        <Link to="/">
          Voltar para a página inicial
        </Link>
      </p>
    </MDBContainer>
  )
}
