import { RouterProvider } from 'react-router-dom';
import Router from '../routes/routes';

import { AppProvider } from '../contexts/App';
import { MDBootstrapProvider } from '../contexts/MDBootstrap';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/common/Loader';

export default function App() {

  return(
    <AppProvider>
      <MDBootstrapProvider>
        <RouterProvider
          router={Router}
          fallbackElement={<Loader />}
        />
      </MDBootstrapProvider>
      <Loader />
      <ToastContainer />
    </AppProvider>
  )
}
