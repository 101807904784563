// REACT
import React from 'react'
import PropTypes from 'prop-types'

import {
  MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBDropdown, MDBDropdownToggle,
  MDBDropdownMenu, MDBDropdownItem, MDBCollapse, MDBNavbarBrand, MDBNavbarToggler,
  MDBContainer, MDBNavbar, MDBIcon, MDBRow, MDBCol, MDBTypography, MDBListGroup,
  MDBListGroupItem, MDBFooter, MDBInput, MDBCheckbox, MDBBtn, MDBCard, MDBCardBody,
  MDBCardTitle, MDBCardText, MDBTextArea, MDBTabs, MDBTabsItem, MDBTabsLink,
  MDBTabsContent, MDBTabsPane, MDBCarousel, MDBCarouselItem, MDBTable,
  MDBTableHead, MDBTableBody, MDBAccordion, MDBAccordionItem, MDBCardLink
} from 'mdb-react-ui-kit'

// CONTEXT
export const MDBootstrapContext = React.createContext({})

// PROVIDER
export const MDBootstrapProvider = ({
  children
}) => {

  return(
    <MDBootstrapContext.Provider
      value={{
        MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBDropdown, MDBDropdownToggle,
        MDBDropdownMenu, MDBDropdownItem, MDBCollapse, MDBNavbarBrand, MDBNavbarToggler,
        MDBContainer, MDBNavbar, MDBIcon, MDBRow, MDBCol, MDBTypography, MDBListGroup,
        MDBListGroupItem, MDBFooter, MDBInput, MDBCheckbox, MDBBtn, MDBCard, MDBCardBody,
        MDBCardTitle, MDBCardText, MDBTextArea, MDBTabs, MDBTabsItem, MDBTabsLink,
        MDBTabsContent, MDBTabsPane, MDBCarousel, MDBCarouselItem, MDBTable,
        MDBTableHead, MDBTableBody, MDBAccordion, MDBAccordionItem, MDBCardLink
      }}
    >
      {children}
    </MDBootstrapContext.Provider>
  )
}

MDBootstrapProvider.propTypes = {
  children: PropTypes.node.isRequired
}
