// REACT
import React, { useContext, useState } from 'react'

// CONTEXT
import { MDBootstrapContext } from '../contexts/MDBootstrap'

// COMPONENTS
import Brand from './common/Brand'
import MenuToggler from './common/MenuToggler'
import Menu from './common/Menu'

import useCheckMobileScreen from '../hooks/useCheckMobileScreen'

export default function Header() {

  const toggleWidth = useCheckMobileScreen()

  const {
    MDBContainer, MDBNavbar
  } = useContext(MDBootstrapContext)

  const [ showBasic, setShowBasic ] = useState(false)

  return (
    // sticky?
    <MDBNavbar expand='lg' light bgColor='light' sticky>
      <MDBContainer>
        {/* BRAND */}
        <Brand />
        {toggleWidth && "Paz e luz a todos."}
        {/* MENU TOGGLER */}
        <MenuToggler
          showBasic={showBasic}
          setShowBasic={setShowBasic}
        />
        {/* MENU */}
        <Menu
          showBasic={showBasic}
        />
      </MDBContainer>
    </MDBNavbar>
  )
}
