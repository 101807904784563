// REACT
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { PATHS } from '../routes/paths'
import { TEXTS } from '../constants/texts'

// CONTEXT
export const AppContext = React.createContext({})

// PROVIDER
export const AppProvider = ({
  children
}) => {

  // TOGGLE APP LOADER
  // ? Esse em particular não vai ser colocado na atualização de estado o
  // ? setToggleLoader(!toggleLoader)
  // ? Por ficar mais legivel a leitura do momento em que deve ser mostrado e
  // ? deve ser escondido
  // ? setToggleLoader(true) ou setToggleLoader(false)
  const [ toggleLoader, setToggleLoader ] = useState(false)

  return(
    <AppContext.Provider
      value={{
        PATHS,
        TEXTS,
        toggleLoader, setToggleLoader
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
}
